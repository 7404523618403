import { gql, useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import type { LazyQueryHook, UpdateQuery } from './types';
import { GENERIC_ITEM } from './fragment';
import type {
  GetGenericItemBySection,
  GetGenericItemBySectionVariables,
} from '../__generated__/GetGenericItemBySection';

export const GET_GENERIC_ITEM_BY_SECTION = gql`
  ${GENERIC_ITEM}
  query GetGenericItemBySection(
    $sectionId: ID!
    $sort: String
    $dataSheetFilters: [DataSheetFilter]
    $from: Int
    $size: Int
    $tag: String
  ) {
    genericItemBySection(
      sectionId: $sectionId
      dataSheetFilters: $dataSheetFilters
      sort: $sort
      from: $from
      size: $size
      tag: $tag
    ) {
      total
      genericItems {
        ...GenericItem
      }
    }
  }
`;

export const useGetGenericItemBySection: LazyQueryHook<GetGenericItemBySection, GetGenericItemBySectionVariables> = (
  options,
) => {
  const [queryFetchData, { called: queryCalled, loading, data, fetchMore, error, networkStatus, client }] =
    useLazyQuery<GetGenericItemBySection, GetGenericItemBySectionVariables>(GET_GENERIC_ITEM_BY_SECTION, options);

  const [result, setResult] = useState<GetGenericItemBySection>(data);
  const [called, setCalled] = useState<boolean>(queryCalled);

  useEffect(() => {
    setResult(data);
  }, [data]);

  const fetchData = useCallback(
    (opt) => {
      if (opt.reset) {
        setResult(null);
        setCalled(false);

        return;
      }

      queryFetchData(opt);
      setCalled(true);
    },
    [setResult, setCalled, queryFetchData],
  );

  const fetchMoreData = useCallback(
    async (variables: GetGenericItemBySectionVariables) => {
      const updateQuery: UpdateQuery<GetGenericItemBySection> = (previousResult, { fetchMoreResult }) => ({
        genericItemBySection: {
          __typename: 'GenericItems',
          total: previousResult?.genericItemBySection?.total,
          genericItems: [
            ...previousResult?.genericItemBySection.genericItems,
            ...fetchMoreResult.genericItemBySection.genericItems,
          ],
        },
      });
      await fetchMore({ updateQuery, variables });
    },
    [fetchMore],
  );

  return {
    networkStatus,
    loading,
    error,
    result,
    called,
    fetchData,
    fetchMoreData,
    client,
  };
};
